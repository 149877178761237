import { useGeo, useGlobal, useShoppingCart } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { fetchRebuildCart, useFetchOrderHistory } from "@sushicorp/services";
import { Button } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";

import Styles from "./RepeatOrder.styles";
import { RepeatOrderProps as Props } from "./RepeatOrder.types";
import ShoppingCartDrawer from "../drawer/ShoppingCartDrawer/ShoppingCartDrawer";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import { notify } from "utils/common.utils";
import { validateShoppingCartUtility } from "utils/shoppingCart.utils";

import FoodLogoDesktopSVG from "../../../../public/assets/images/food-logo-desktop.svg";
import FoodLogoMobileSVG from "../../../../public/assets/images/food-logo.svg";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_CART_DRAWER, WITH_PURCHASE } = FEATURE_FLAGS;

const RepeatOrder: React.FC<Props> = props => {
  const { className, lastOrderDoneId } = props;
  const t = useI18n();
  const { push } = useRouter();
  const { selectedCoordinates } = useGeo();
  const { shoppingCart } = useShoppingCart();
  const [opened, setOpened] = useState(false);
  const auth = useAuth();
  const { setErrorConfig, setLoadingConfig } = useGlobal();
  const { selectedCatalogueId: catalogueId } = useCatalogues();
  const orders = useFetchOrderHistory({
    auth,
    catalogueId,
    category: "DONE",
    notify
  });
  const { isAnonymous, uid } = auth;
  const { data: ordersDone } = orders;
  const orderActive = ordersDone?.[0];
  const { id: shoppingCartId } = shoppingCart ?? {};
  const { orderDate, seq_id } = orderActive ?? {};

  /**
   * Function to repeat order.
   *
   * @since 3.0.0
   */
  const repeatOrderHandler = useCallback(async () => {
    if (!uid) return;

    try {
      if (!selectedCoordinates) throw new Error(t.errors.geo.noCoordinates);
      const { lat, lng } = selectedCoordinates ?? {};
      setLoadingConfig({ show: true });
      await fetchRebuildCart(lastOrderDoneId, shoppingCartId);
      await validateShoppingCartUtility(lat, lng, !!isAnonymous, uid);

      if (WITH_CART_DRAWER) {
        setOpened(true);
      } else {
        push("/cart");
      }
    } catch (error) {
      setErrorConfig({
        show: true,

        title: error.message
      });
    } finally {
      setLoadingConfig({ show: false });
    }
  }, [
    uid,
    selectedCoordinates,
    t.errors.geo.noCoordinates,
    setLoadingConfig,
    lastOrderDoneId,
    shoppingCartId,
    isAnonymous,
    push,
    setErrorConfig
  ]);

  return (
    <Styles className={`RepeatOrder ${className}`}>
      <div className="RepeatOrder__image">
        <p className="RepeatOrder__title">{t.repeatOrder.title}</p>
      </div>
      <div className="RepeatOrder__container">
        <div className="RepeatOrder__content">
          <FoodLogoMobileSVG className="RepeatOrder__food-logo RepeatOrder__food-logo--mobile" />
          <FoodLogoDesktopSVG className="RepeatOrder__food-logo RepeatOrder__food-logo--desktop" />
          <p className="RepeatOrder__order-description">
            {t.repeatOrder.placeholder.description} {orderDate}
          </p>
          <p className="RepeatOrder__order-id">
            {t.repeatOrder.placeholder.order} #{seq_id}
          </p>
        </div>

        <div className="RepeatOrder__buttons">
          <Button
            className="RepeatOrder__buttons--repeat"
            // TODO: add route to repeat order
            onClick={repeatOrderHandler}
          >
            {t.repeatOrder.repeatOrder}
          </Button>
        </div>
      </div>
      {WITH_CART_DRAWER && WITH_PURCHASE ? (
        <ShoppingCartDrawer opened={opened} onClose={() => setOpened(false)} />
      ) : null}
    </Styles>
  );
};

RepeatOrder.defaultProps = { className: "" };

export default RepeatOrder;
